import React, { createContext, useContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { StatusContext } from "@/context/StatusContext";
import { client } from "@/helpers";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { setWaiting, setError } = useContext(StatusContext);
  const [token, setToken] = useLocalStorage("token");
  const [tokenContent, setTokenContent] = useLocalStorage("tokenContent");
  const [user, setUser] = useState({});

  useEffect(() => {
    if (token) {
      if (new Date() > new Date(tokenContent.exp * 1000)) logout();
    }
  });

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        setWaiting(true);
        const decoded = jwt_decode(token);
        setTokenContent(decoded);

        const { data } = await client(`/users/${decoded.username}`, {
          token,
          logout,
        });

        setUser(data);
      } catch (error) {
        setError(error.message);
        logout();
      } finally {
        setWaiting(false);
      }
    };

    if (token) getUserInfo();
  }, [token]);

  const logout = () => {
    setToken("");
    setTokenContent("");
    setUser({});
  };

  const updateUser = (data) => {
    setUser({ ...user, ...data });
  };

  return (
    <UserContext.Provider
      value={{ token, tokenContent, setToken, user, logout, updateUser }}
    >
      {children}
    </UserContext.Provider>
  );
};
