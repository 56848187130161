import React, { Suspense, useContext } from "react";
import "@/css/styles.css";

import { BrowserRouter as Router } from "react-router-dom";

import { AppError, AppWaiting, Waiting } from "@/components/AppStatus";
import { Footer } from "@/components/Footer";
import { Nav } from "@/components/Nav";
import { Routes } from "@/Routes";
import { useScreenSize } from "@/hooks/useScreenSize";
import { SmallScreen } from "@/components/SmallScreen";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "@/components/ErrorFallback";
import { UserContext } from "@/context/UserContext";

export default function App() {
  const { width, height } = useScreenSize();
  const { logout } = useContext(UserContext);

  const isValidScreenSize = width >= 1000 && height >= 600;

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        logout();
      }}
    >
      <Suspense fallback={<Waiting />}>
        <Router>
          {isValidScreenSize ? (
            <section className="min-h-screen">
              <Nav />
              <Routes />
              <Footer />
              <AppError />
              <AppWaiting />
            </section>
          ) : (
            <SmallScreen />
          )}
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
