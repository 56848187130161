import { Button } from "@/components/Form";
import { FullPageSection, FullPageText } from "@/components/FullPageComponents";
import { Title } from "@/components/Title";
import React from "react";

export const ErrorFallback = ({ error = {}, resetErrorBoundary }) => {
  return (
    <FullPageSection>
      <div className="p-4 w-96">
        <Title>Ooops, houbo un erro grave na aplicación 🥺</Title>
        <FullPageText>
          As veces as cousas fallan, pero imos a ver se nos recuperamos.
        </FullPageText>
        <Button onClick={() => resetErrorBoundary()} className="mb-4">
          Reiniciar
        </Button>
        <FullPageText>
          Se non funciona proba a borrar a caché do navegador e se nada
          funciona,{" "}
          <a
            href="http://www.galicia.asfes.org/asf-galicia/quen-somos/contacto/"
            target="_blank"
            rel="noreferrer"
          >
            avísanos
          </a>
          , por favor.
        </FullPageText>
        {error.message ? (
          <>
            <FullPageText>
              Por se tes curiosidade isto é o que pasou:
            </FullPageText>
            <p className="font-mono border border-gray-400 border-dotted p-1 text-gray-400 text-sm">
              {error.message}
            </p>
          </>
        ) : null}
      </div>
    </FullPageSection>
  );
};
