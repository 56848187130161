import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "@/context/UserContext";
import { IconAdmin, IconHome, IconLogout, IconUser } from "@/components/Icons";

export const Nav = () => {
  const { tokenContent, logout, user } = useContext(UserContext);

  return (
    <nav className="absolute top-4 right-4 text-blue-700 font-bold z-10">
      <ul className="flex">
        <li>
          <Link to="/" className="flex items-center">
            Inicio <IconHome className="ml-1" />
          </Link>
        </li>

        {tokenContent ? (
          <>
            <li className="ml-4">
              {tokenContent.role === "admin" ? (
                <Link to="/admin" className="flex items-center">
                  Admin <IconAdmin className="ml-1" />
                </Link>
              ) : (
                <Link to="/mapas" className="flex items-center">
                  {user.name}
                  <IconUser className="ml-1" />
                </Link>
              )}
            </li>
            <li
              onClick={() => logout()}
              className="flex items-center ml-4 cursor-pointer"
            >
              Saír <IconLogout className="ml-1" />
            </li>
          </>
        ) : null}
      </ul>
    </nav>
  );
};
