import React, { createContext, useState } from "react";
import { randomPointInBoundingBox } from "@/helpers";
export const StatusContext = createContext();

export const StatusProvider = ({ children }) => {
  const bbox = process.env.CLIENT_GALICIA_DEFAULT_BBOX.split(",").map(Number);

  const [mapCenter, setMapCenter] = useState(
    randomPointInBoundingBox({ bbox })
  );

  const [error, setError] = useState(null);
  const [waiting, setWaiting] = useState(false);

  const setRandomCenter = () => {
    const newCenter = randomPointInBoundingBox({ bbox });

    setMapCenter(newCenter);
  };

  return (
    <StatusContext.Provider
      value={{
        mapCenter,
        setRandomCenter,
        error,
        setError,
        waiting,
        setWaiting,
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};
