import { mapIcon } from "@/components/MapIcons";
import random from "lodash/random";
import escapeHtml from "escape-html";
import { Text, Node } from "slate";

export const randomPointInBoundingBox = ({
  bbox = [-8.6486, 42.0654, -7.1156, 43.4494],
}) => {
  return [random(bbox[1], bbox[3]), random(bbox[0], bbox[2])];
};

export const client = (
  endpoint,
  { body, token, logout, formData = false, ...customConfig } = {}
) => {
  const headers = {};

  if (!formData) {
    headers["Content-type"] = "application/json";
  }

  if (token) {
    headers["Authorization"] = token;
  }

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = formData ? body : JSON.stringify(body);
  }

  return fetch(`${process.env.CLIENT_API}${endpoint}`, config).then(
    async (response) => {
      if (response.status === 401) {
        logout && logout();
      }

      const data = await response.json();

      if (response.ok) {
        return data;
      } else {
        return Promise.reject(new Error(data.error));
      }
    }
  );
};

export const PALETTE = {
  orange: "#D95F02",
  yellow: "#D3C229",
  green: "#66A61E",
  purple: "#984EA3",
  red: "#E41A1C",
};

export const DEFAULT_COLOR = "orange";

export const layerStyle = ({ layer, opacity, fillOpacity }) => {
  const color = PALETTE[layer.feature.properties.color];

  if (layer.feature.geometry.type === "Point") {
    layer.setIcon(mapIcon({ color, opacity }));
  } else {
    layer.setStyle({
      color,
      fillColor: color,
      opacity,
      fillOpacity,
    });
  }
};

export const setLayerStyle = ({ geoJsonLayer, currentSelected }) => {
  geoJsonLayer.current &&
    geoJsonLayer.current.eachLayer((layer) => {
      const opacity = currentSelected
        ? layer.feature.properties.id === currentSelected
          ? 1 //high
          : 0.3 //dim
        : 0.7; //normal

      const fillOpacity = currentSelected
        ? layer.feature.properties.id === currentSelected
          ? 0.6 //high
          : 0.1 //dim
        : 0.3; //normal

      layerStyle({ layer, opacity, fillOpacity });
    });
};

export const serializeHTML = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    return string;
  }

  const children = node.children.map((n) => serializeHTML(n)).join("");
  if (children === "") return;

  switch (node.type) {
    case "paragraph":
      return `<p class="mb-2">${children}</p>`;
    case "link":
      return `<a href="${escapeHtml(
        node.url
      )}" target="_blank" rel="noreferrer">${children}</a>`;
    default:
      return children;
  }
};

export const serializePlain = (nodes) => {
  return nodes.map((n) => Node.string(n)).join("\n");
};
