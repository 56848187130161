import React from "react";
import cn from "classnames";

export const IconClose = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={cn("fill-current", className)}
      {...props}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
    </svg>
  );
};

export const IconLogout = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      width="18px"
      height="18px"
      className={cn("fill-current", className)}
      {...props}
    >
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <g>
          <path d="M5,5h6c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h6c0.55,0,1-0.45,1-1v0 c0-0.55-0.45-1-1-1H5V5z" />
          <path d="M20.65,11.65l-2.79-2.79C17.54,8.54,17,8.76,17,9.21V11h-7c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h7v1.79 c0,0.45,0.54,0.67,0.85,0.35l2.79-2.79C20.84,12.16,20.84,11.84,20.65,11.65z" />
        </g>
      </g>
    </svg>
  );
};

export const IconHome = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      className={cn("fill-current", className)}
      {...props}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z" />
    </svg>
  );
};

export const IconAdmin = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      height="24"
      width="24"
      className={cn("fill-current", className)}
      {...props}
    >
      <g>
        <rect fill="none" height="24" width="24" />
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <path d="M17,11c0.34,0,0.67,0.04,1,0.09V7.58c0-0.8-0.47-1.52-1.2-1.83l-5.5-2.4c-0.51-0.22-1.09-0.22-1.6,0l-5.5,2.4 C3.47,6.07,3,6.79,3,7.58v3.6c0,4.54,3.2,8.79,7.5,9.82c0.55-0.13,1.08-0.32,1.6-0.55C11.41,19.47,11,18.28,11,17 C11,13.69,13.69,11,17,11z" />
          <path d="M17,13c-2.21,0-4,1.79-4,4c0,2.21,1.79,4,4,4s4-1.79,4-4C21,14.79,19.21,13,17,13z M17,14.38c0.62,0,1.12,0.51,1.12,1.12 s-0.51,1.12-1.12,1.12s-1.12-0.51-1.12-1.12S16.38,14.38,17,14.38z M17,19.75c-0.93,0-1.74-0.46-2.24-1.17 c0.05-0.72,1.51-1.08,2.24-1.08s2.19,0.36,2.24,1.08C18.74,19.29,17.93,19.75,17,19.75z" />
        </g>
      </g>
    </svg>
  );
};

export const IconUser = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      className={cn("fill-current", className)}
      {...props}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z" />
    </svg>
  );
};

export const IconEdit = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={cn("fill-current", className)}
      {...props}
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <g>
            <path d="M3,17.46l0,3.04C3,20.78,3.22,21,3.5,21h3.04c0.13,0,0.26-0.05,0.35-0.15L17.81,9.94l-3.75-3.75L3.15,17.1 C3.05,17.2,3,17.32,3,17.46z" />
          </g>
          <g>
            <path d="M20.71,5.63l-2.34-2.34c-0.39-0.39-1.02-0.39-1.41,0l-1.83,1.83l3.75,3.75l1.83-1.83C21.1,6.65,21.1,6.02,20.71,5.63z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IconCancel = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={cn("fill-current", className)}
      {...props}
    >
      <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
      <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z" />
    </svg>
  );
};

export const IconSave = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={cn("fill-current", className)}
      {...props}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M17.59 3.59c-.38-.38-.89-.59-1.42-.59H5c-1.11 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V7.83c0-.53-.21-1.04-.59-1.41l-2.82-2.83zM12 19c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm1-10H7c-1.1 0-2-.9-2-2s.9-2 2-2h6c1.1 0 2 .9 2 2s-.9 2-2 2z" />
    </svg>
  );
};

export const IconQuestionPin = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      className={cn("fill-current", className)}
      {...props}
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <path d="M12,2c-4.2,0-8,3.22-8,8.2c0,3.18,2.45,6.92,7.34,11.22c0.38,0.33,0.95,0.33,1.33,0C17.55,17.12,20,13.38,20,10.2 C20,5.22,16.2,2,12,2z M12.01,16c-0.59,0-1.05-0.47-1.05-1.05c0-0.59,0.47-1.04,1.05-1.04c0.59,0,1.04,0.45,1.04,1.04 C13.05,15.53,12.61,16,12.01,16z M14.52,9.83c-0.63,0.93-1.23,1.21-1.56,1.81c-0.08,0.14-0.13,0.26-0.16,0.49 c-0.05,0.39-0.36,0.68-0.75,0.68h-0.03c-0.44,0-0.79-0.38-0.75-0.82c0.03-0.27,0.09-0.57,0.25-0.84c0.41-0.73,1.18-1.16,1.63-1.8 c0.48-0.68,0.21-1.94-1.14-1.94c-0.61,0-1.01,0.32-1.26,0.7c-0.19,0.29-0.57,0.39-0.89,0.25c-0.42-0.18-0.6-0.7-0.34-1.07 C10.03,6.55,10.88,6,12,6c1.23,0,2.08,0.56,2.51,1.26C14.87,7.87,15.09,8.99,14.52,9.83z" />
      </g>
    </svg>
  );
};

export const IconPhoto = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      className={cn("fill-current", className)}
      {...props}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M3 8c0 .55.45 1 1 1s1-.45 1-1V6h2c.55 0 1-.45 1-1s-.45-1-1-1H5V2c0-.55-.45-1-1-1s-1 .45-1 1v2H1c-.55 0-1 .45-1 1s.45 1 1 1h2v2z" />
      <circle cx="13" cy="14" r="3" />
      <path d="M21 6h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65h-6.4c.17.3.28.63.28 1 0 1.1-.9 2-2 2H6v1c0 1.1-.9 2-2 2-.37 0-.7-.11-1-.28V20c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
    </svg>
  );
};

export const IconDelete = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={cn("fill-current", className)}
      {...props}
    >
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <path d="M6,19c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V7H6V19z M9.17,12.59c-0.39-0.39-0.39-1.02,0-1.41c0.39-0.39,1.02-0.39,1.41,0 L12,12.59l1.41-1.41c0.39-0.39,1.02-0.39,1.41,0s0.39,1.02,0,1.41L13.41,14l1.41,1.41c0.39,0.39,0.39,1.02,0,1.41 s-1.02,0.39-1.41,0L12,15.41l-1.41,1.41c-0.39,0.39-1.02,0.39-1.41,0c-0.39-0.39-0.39-1.02,0-1.41L10.59,14L9.17,12.59z M18,4h-2.5 l-0.71-0.71C14.61,3.11,14.35,3,14.09,3H9.91c-0.26,0-0.52,0.11-0.7,0.29L8.5,4H6C5.45,4,5,4.45,5,5s0.45,1,1,1h12 c0.55,0,1-0.45,1-1S18.55,4,18,4z" />
      </g>
    </svg>
  );
};

export const IconPassword = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={cn("fill-current", className)}
      {...props}
    >
      <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" />
      <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM8.9 6c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2H8.9V6zM15 16h-2v2c0 .55-.45 1-1 1s-1-.45-1-1v-2H9c-.55 0-1-.45-1-1s.45-1 1-1h2v-2c0-.55.45-1 1-1s1 .45 1 1v2h2c.55 0 1 .45 1 1s-.45 1-1 1z" />
    </svg>
  );
};

export const IconPublic = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={cn("fill-current", className)}
      {...props}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z" />
    </svg>
  );
};
export const IconDownload = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      className={cn("fill-current", className)}
      {...props}
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <path d="M16.59,9H15V4c0-0.55-0.45-1-1-1h-4C9.45,3,9,3.45,9,4v5H7.41c-0.89,0-1.34,1.08-0.71,1.71l4.59,4.59 c0.39,0.39,1.02,0.39,1.41,0l4.59-4.59C17.92,10.08,17.48,9,16.59,9z M5,19c0,0.55,0.45,1,1,1h12c0.55,0,1-0.45,1-1s-0.45-1-1-1H6 C5.45,18,5,18.45,5,19z" />
      </g>
    </svg>
  );
};

export const IconAdd = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={cn("fill-current", className)}
      {...props}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z" />
    </svg>
  );
};

export const IconPin = ({ className, fill = "", ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("fill-current", className)}
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill={fill}
        d="M12 2c-4.2 0-8 3.22-8 8.2 0 3.18 2.45 6.92 7.34 11.23.38.33.95.33 1.33 0C17.55 17.12 20 13.38 20 10.2 20 5.22 16.2 2 12 2zm0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
      />
    </svg>
  );
};

export const IconArea = ({ className, fill = "", ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("fill-current", className)}
      {...props}
    >
      <rect
        x="2.62851"
        y="2.62851"
        width="18.743"
        height="18.743"
        fill={fill}
      />
    </svg>
  );
};

export const IconLine = ({ className, ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("fill-current", className)}
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M20.053 4.732c.55.953.22 2.182-.733 2.732a1.7 1.7 0 01-.476.194l-2.308 3.855c.123.113.23.26.32.415.55.953.221 2.182-.732 2.732-.952.55-2.182.22-2.732-.732a1.767 1.767 0 01-.2-.485l-3.482-.934c-.114.124-.26.231-.416.321-.156.09-.322.163-.485.2l-1.66 4.224c.123.113.225.25.315.406.55.953.22 2.182-.732 2.732-.953.55-2.182.22-2.732-.732a2.006 2.006 0 01.732-2.732 1.7 1.7 0 01.477-.194l1.674-4.22a1.767 1.767 0 01-.32-.416 2.006 2.006 0 01.731-2.732 2.006 2.006 0 012.732.732c.09.156.163.322.2.485l3.483.934c.114-.123.26-.23.415-.32.156-.09.322-.163.486-.2l2.294-3.858a1.7 1.7 0 01-.316-.407A2.006 2.006 0 0117.32 4a2.006 2.006 0 012.733.732zm0 0c.55.953.22 2.182-.733 2.732a1.7 1.7 0 01-.476.194l-2.308 3.855c.123.113.23.26.32.415.55.953.221 2.182-.732 2.732-.952.55-2.182.22-2.732-.732a1.767 1.767 0 01-.2-.485l-3.482-.934c-.114.124-.26.231-.416.321-.156.09-.322.163-.485.2l-1.66 4.224c.123.113.225.25.315.406.55.953.22 2.182-.732 2.732-.953.55-2.182.22-2.732-.732a2.006 2.006 0 01.732-2.732 1.7 1.7 0 01.477-.194l1.674-4.22a1.767 1.767 0 01-.32-.416 2.006 2.006 0 01.731-2.732 2.006 2.006 0 012.732.732c.09.156.163.322.2.485l3.483.934c.114-.123.26-.23.415-.32.156-.09.322-.163.486-.2l2.294-3.858a1.7 1.7 0 01-.316-.407A2.006 2.006 0 0117.32 4a2.006 2.006 0 012.733.732z" />
    </svg>
  );
};

export const IconCircle = ({ className, ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("fill-current", className)}
      {...props}
    >
      <rect width="24" height="24" fill="none" />
      <path d="M13.2653 5.26165C12.9358 5.65814 12.4389 5.91058 11.883 5.91058C11.3462 5.91058 10.8644 5.67517 10.5352 5.30197C7.45316 5.97286 5.14511 8.7169 5.14511 12C5.14511 15.7859 8.21415 18.8549 12 18.8549C15.7859 18.8549 18.8549 15.7859 18.8549 12C18.8549 8.64644 16.4467 5.85534 13.2653 5.26165ZM10.2742 3.31319C10.5686 2.72281 11.1785 2.31727 11.883 2.31727C12.5687 2.31727 13.1646 2.70137 13.4675 3.26614C17.6595 3.96529 20.8549 7.60949 20.8549 12C20.8549 16.8904 16.8904 20.8549 12 20.8549C7.10958 20.8549 3.14511 16.8904 3.14511 12C3.14511 7.70013 6.20992 4.11608 10.2742 3.31319ZM13.6797 11.7733C13.6797 12.7655 12.8753 13.5699 11.883 13.5699C10.8907 13.5699 10.0863 12.7655 10.0863 11.7733C10.0863 10.781 10.8907 9.97662 11.883 9.97662C12.8753 9.97662 13.6797 10.781 13.6797 11.7733Z" />
    </svg>
  );
};

export const IconRectangle = ({ className, ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={cn("fill-current", className)}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="none" />
      <path d="M2.82715 4.63107C2.82715 5.32407 3.2195 5.92544 3.79419 6.22514V17.4642C3.2195 17.7639 2.82715 18.3652 2.82715 19.0582C2.82715 20.0505 3.63154 20.8549 4.6238 20.8549C5.32131 20.8549 5.92598 20.4574 6.22368 19.8766H17.4511C17.7488 20.4574 18.3535 20.8549 19.051 20.8549C20.0432 20.8549 20.8476 20.0505 20.8476 19.0582C20.8476 18.3626 20.4523 17.7594 19.8741 17.4608V6.22849C20.4523 5.92993 20.8476 5.32667 20.8476 4.63107C20.8476 3.63881 20.0432 2.83442 19.051 2.83442C18.3599 2.83442 17.7599 3.2246 17.4594 3.79669H6.21537C5.91483 3.2246 5.31488 2.83442 4.6238 2.83442C3.63154 2.83442 2.82715 3.63881 2.82715 4.63107ZM6 17.8766H5.97726C5.92064 17.8118 5.85945 17.7511 5.79419 17.6951V5.99426C5.8648 5.93358 5.93064 5.86751 5.99108 5.79669H17.6837C17.7423 5.86535 17.8059 5.92954 17.8741 5.98869V6H18V18H6V17.8766Z" />
    </svg>
  );
};

export const IconPolygon = ({ className, ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("fill-current", className)}
      {...props}
    >
      <rect width="24" height="24" fill="none" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9613 6.53107C14.2955 6.17362 14.5 5.69348 14.5 5.16557C14.5 4.061 13.6046 3.16557 12.5 3.16557C11.3954 3.16557 10.5 4.061 10.5 5.16557C10.5 5.69348 10.7045 6.17362 11.0387 6.53107L5.90539 15.4222C4.86497 15.4929 4.04291 16.3592 4.04291 17.4175C4.04291 18.5221 4.93834 19.4175 6.04291 19.4175C6.85042 19.4175 7.54615 18.939 7.86196 18.25H16.9725C17.2883 18.939 17.9841 19.4175 18.7916 19.4175C19.8961 19.4175 20.7916 18.5221 20.7916 17.4175C20.7916 16.42 20.0612 15.593 19.1061 15.4421L13.9613 6.53107ZM17.1675 16.25C17.1851 16.2257 17.2031 16.2017 17.2217 16.1782L12.5 8L7.73686 16.25H17.1675Z"
      />
    </svg>
  );
};

export const IconAll = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      className={cn("fill-current", className)}
      {...props}
    >
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <path d="M18,4h2c1.1,0,2,0.9,2,2v2c0,0.55-0.45,1-1,1h0c-0.55,0-1-0.45-1-1V6h-2c-0.55,0-1-0.45-1-1v0C17,4.45,17.45,4,18,4z M4,8 l0-2h2c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H4C2.9,4,2,4.9,2,6l0,2c0,0.55,0.45,1,1,1h0C3.55,9,4,8.55,4,8z M20,16v2h-2 c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h2c1.1,0,2-0.9,2-2v-2c0-0.55-0.45-1-1-1h0C20.45,15,20,15.45,20,16z M6,18H4v-2 c0-0.55-0.45-1-1-1h0c-0.55,0-1,0.45-1,1v2c0,1.1,0.9,2,2,2h2c0.55,0,1-0.45,1-1v0C7,18.45,6.55,18,6,18z M16,8H8c-1.1,0-2,0.9-2,2 v4c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-4C18,8.9,17.1,8,16,8z" />
      </g>
    </svg>
  );
};

export const IconDoc = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      className={cn("fill-current", className)}
      {...props}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M14.59 2.59c-.38-.38-.89-.59-1.42-.59H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.82-4.83zM15 18H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm0-4H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm-2-6V3.5L18.5 9H14c-.55 0-1-.45-1-1z" />
    </svg>
  );
};

export const IconUnselect = ({ className, ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("fill-current", className)}
      {...props}
    >
      <path d="M3 5h2V3c-1.1 0-2 .9-2 2zm0 8h2v-2H3v2zm4 8h2v-2H7v2zM3 9h2V7H3v2zm10-6h-2v2h2V3zm6 0v2h2c0-1.1-.9-2-2-2zM5 21v-2H3c0 1.1.9 2 2 2zm-2-4h2v-2H3v2zM9 3H7v2h2V3zm2 18h2v-2h-2v2zm8-8h2v-2h-2v2zm0 8c1.1 0 2-.9 2-2h-2v2zm0-12h2V7h-2v2zm0 8h2v-2h-2v2zm-4 4h2v-2h-2v2zm0-16h2V3h-2v2zM13.131 11.955l3.414 3.414-1.143 1.143-3.414-3.414-3.53 3.53-1.152-1.151 3.53-3.53-3.398-3.398 1.144-1.143 3.397 3.397 3.547-3.547 1.152 1.152-3.547 3.547z" />
    </svg>
  );
};

export const IconUnLink = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      className={cn("fill-current", className)}
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M14 9h2.87c1.46 0 2.8.98 3.08 2.42.31 1.64-.74 3.11-2.22 3.48l1.53 1.53c1.77-.91 2.95-2.82 2.7-5.01C21.68 8.86 19.37 7 16.79 7H14c-.55 0-1 .45-1 1s.45 1 1 1zM3.51 3.51A.996.996 0 102.1 4.92l2.64 2.64c-1.77.91-2.95 2.82-2.7 5.01C2.32 15.14 4.63 17 7.21 17H10c.55 0 1-.45 1-1s-.45-1-1-1H7.13c-1.46 0-2.8-.98-3.08-2.42-.31-1.64.75-3.11 2.22-3.48l2.12 2.12c-.23.19-.39.46-.39.78 0 .55.45 1 1 1h1.17l8.9 8.9a.996.996 0 101.41-1.41L3.51 3.51zM14 11l1.71 1.71A1.003 1.003 0 0015 11h-1z" />
    </svg>
  );
};

export const IconLink = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      className={cn("fill-current", className)}
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M17 7h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c1.65 0 3 1.35 3 3s-1.35 3-3 3h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c2.76 0 5-2.24 5-5s-2.24-5-5-5zm-9 5c0 .55.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1H9c-.55 0-1 .45-1 1zm2 3H7c-1.65 0-3-1.35-3-3s1.35-3 3-3h3c.55 0 1-.45 1-1s-.45-1-1-1H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h3c.55 0 1-.45 1-1s-.45-1-1-1z" />
    </svg>
  );
};

export const IconBold = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      className={cn("fill-current", className)}
      {...props}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H8c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h5.78c2.07 0 3.96-1.69 3.97-3.77.01-1.53-.85-2.84-2.15-3.44zM10 6.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3zm3.5 9H10v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z" />
    </svg>
  );
};

export const IconItalic = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      className={cn("fill-current", className)}
      {...props}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M10 5.5c0 .83.67 1.5 1.5 1.5h.71l-3.42 8H7.5c-.83 0-1.5.67-1.5 1.5S6.67 18 7.5 18h5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-.71l3.42-8h1.29c.83 0 1.5-.67 1.5-1.5S17.33 4 16.5 4h-5c-.83 0-1.5.67-1.5 1.5z" />
    </svg>
  );
};

export const IconSettings = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      strokeWidth={1.5}
      className={cn("fill-current", className)}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </svg>
  );
};

export const IconASF = ({ className, ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("fill-current", className)}
      {...props}
    >
      <path d="M12.608.068c.39.05.761.084 1.15.135.507.084 1.014.203 1.538.304-.017.101-.017.169-.034.22l-2.383 8.518c-.05.169-.118.203-.27.169-1.268-.152-2.536-.287-3.803-.422-1.657-.186-3.296-.372-4.952-.541-.98-.102-1.961-.22-2.941-.321-.068 0-.119-.017-.237-.034.287-.609.507-1.217.828-1.775C3.414 2.941 6.287.896 10.107.22 10.445.152 10.8.135 11.155.1c.084-.017.152-.033.237-.033h1.216zM12.101 12c-1.081 3.87-2.146 7.69-3.228 11.544-.456-.152-.912-.288-1.335-.474-4.023-1.74-6.473-4.766-7.301-9.076-.102-.574-.119-1.166-.17-1.757C.035 12.034.119 12 .305 12h11.797zM10.073 23.763c.304-.726.592-1.42.88-2.13 1.284-3.143 2.585-6.27 3.87-9.413.067-.17.152-.22.338-.22h4.31c.185 0 .27.068.337.254.66 2.095 1.319 4.174 1.995 6.27.05.169.034.287-.068.422-2.011 2.738-4.681 4.378-8.045 4.902a12.4 12.4 0 01-3.448 0 .53.53 0 00-.169-.085zM19.842 3.008c2.282 2.046 3.617 4.564 4.006 7.64-.22-.017-.406-.034-.592-.05l-1.842-.204c-.152-.017-.203-.084-.22-.202-.32-1.623-.642-3.262-.963-4.885l-.406-2.079c-.017-.067-.017-.135-.034-.186.017-.017.034-.017.051-.034zM21.566 12.017c.102-.017.152-.017.186-.017h1.96c.119 0 .204.017.204.169a12.117 12.117 0 01-1.217 5.104c-.017.017-.034.05-.068.102-.355-1.792-.71-3.566-1.065-5.358zM19.132 10.14a196.112 196.112 0 01-3.295-.371c.608-1.47 1.183-2.89 1.791-4.36.507 1.605.997 3.143 1.504 4.732z" />
    </svg>
  );
};

export const IconSpin = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
          <stop stopColor="#fff" stopOpacity="0" offset="0%" />
          <stop stopColor="#fff" stopOpacity=".631" offset="63.146%" />
          <stop stopColor="#fff" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)">
          <path
            d="M36 18c0-9.94-8.06-18-18-18"
            id="Oval-2"
            stroke="url(#a)"
            strokeWidth="2"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </path>
          <circle fill="#fff" cx="36" cy="18" r="1">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </g>
    </svg>
  );
};
