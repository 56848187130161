import React from "react";
import cn from "classnames";

export const Input = ({
  name,
  id,
  onChange,
  onClick = () => {},
  placeholder = "",
  type = "text",
  required = false,
  className = "",
  labelClass = "",
  value,
  readOnly = false,
  inputClassName = "",
  help = "",
  ...props
}) => {
  return (
    <fieldset className={className}>
      <label
        htmlFor={id}
        className={`block text-sm mb-2 ${labelClass} font-medium`}
      >
        {name}
      </label>
      <input
        className={cn(`p-2 border border-gray-400 w-full`, inputClassName)}
        type={type}
        name={id}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        onClick={onClick}
        required={required}
        value={value}
        readOnly={readOnly}
        title={help}
        {...props}
      />
      {help ? <p className={"mt-2 text-xs"}>{help}</p> : null}
    </fieldset>
  );
};

export const TextArea = ({
  name,
  id,
  onChange,
  placeholder = "",
  required = false,
  className = "",
  labelClass,
  value,
  ...props
}) => {
  return (
    <fieldset className={className}>
      <label htmlFor={id} className={`block text-sm mb-2 ${labelClass}`}>
        {name}
      </label>
      <textarea
        className="p-2 border border-gray-400 w-full resize-none h-40"
        name={id}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
        value={value}
        {...props}
      ></textarea>
    </fieldset>
  );
};

export const Button = ({ children, className = "", ...props }) => (
  <button className={cn("btn-positive", className)} {...props}>
    {children}
  </button>
);

export const InlineInput = ({
  type = "text",
  name,
  value,
  onChange,
  required = false,
  placeholder = "",
}) => {
  return (
    <input
      className="p-1 border border-gray-400 "
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      required={required}
      value={value}
    />
  );
};

export const InlineButton = ({
  children,
  onClick,
  className = "btn-inline-positive",
  ...props
}) => {
  return (
    <button onClick={onClick} className={className} {...props}>
      {children}
    </button>
  );
};
