import React, { useContext } from "react";
import { StatusContext } from "@/context/StatusContext";
import { IconClose, IconSpin } from "@/components/Icons";

export const AppError = () => {
  const { error, setError } = useContext(StatusContext);

  return (
    <>
      {error ? (
        <div className="absolute top-0 w-full p-4 bg-red-700 text-white flex z-10">
          <p className="flex-grow">{error}</p>
          <div
            className="w-10 cursor-pointer flex items-center"
            onClick={() => setError(null)}
          >
            <IconClose className="text-white" />
          </div>
        </div>
      ) : null}
    </>
  );
};

export const AppWaiting = () => {
  const { waiting } = useContext(StatusContext);

  return <>{waiting ? <Waiting /> : null}</>;
};

export const Waiting = () => {
  return (
    <div className="full-panel grid place-content-center bg-blue-700 opacity-50">
      <IconSpin />
    </div>
  );
};

export const InlineWaiting = () => {
  return (
    <div className="text-center font-black text-3xl text-blue-700">...</div>
  );
};
