import { UserContext } from "@/context/UserContext";
import React, { lazy, useContext } from "react";

import {
  Switch,
  Route,
  useLocation,
  useParams,
  Redirect,
} from "react-router-dom";
import { FullPageSection, FullPageText } from "@/components/FullPageComponents";
import { Title } from "@/components/Title";

const Gallery = lazy(() => import("@/pages/Gallery"));
const HomePage = lazy(() => import("@/pages/HomePage"));
const Login = lazy(() => import("@/pages/Login"));
const NewAccount = lazy(() => import("@/pages/NewAccount"));
const Maps = lazy(() => import("@/pages/Maps"));
const Admin = lazy(() => import("@/pages/Admin"));
const AdminUser = lazy(() => import("@/pages/AdminUser"));
const Editor = lazy(() => import("@/pages/Editor"));
const MapViewer = lazy(() => import("@/pages/MapViewer"));
const Legal = lazy(() => import("@/pages/Legal"));
const About = lazy(() => import("@/pages/About"));
const ValidateUser = lazy(() => import("@/pages/ValidateUser"));
const RequestPass = lazy(() => import("@/pages/RequestPass"));
const SetPass = lazy(() => import("@/pages/SetPass"));
const Settings = lazy(() => import("@/pages/Settings"));
const RedirectToViewer = ({ children }) => {
  const { username, id } = useParams();
  const { token, tokenContent } = useContext(UserContext);

  if (tokenContent.username === "admin") {
    return children;
  }

  if (!token || !tokenContent || username !== tokenContent.username) {
    return <Redirect to={`/mapas/${username}/${id}`} />;
  }

  return children;
};

const NotFound = () => {
  return (
    <FullPageSection>
      <div className="p-4">
        <Title>Sentímolo, pero non hai nada por aquí</Title>
        <FullPageText>Ou sexa, erro 404, páxina non atopada.</FullPageText>
      </div>
    </FullPageSection>
  );
};

export const Routes = () => {
  const location = useLocation();

  return (
    <main>
      <Switch location={location}>
        <Route exact path="/" render={() => <HomePage />} />
        <Route exact path="/galeria" render={() => <Gallery />} />
        <Route exact path="/login" render={() => <Login />} />
        <Route exact path="/validar/:code" render={() => <ValidateUser />} />
        <Route exact path="/nova-conta" render={() => <NewAccount />} />
        <Route
          exact
          path="/pedir-nova-contrasinal"
          render={() => <RequestPass />}
        />
        <Route
          exact
          path="/restablecer-contrasinal/:code"
          render={() => <SetPass />}
        />

        <Route exact path="/configurar" render={() => <Settings />} />

        <Route exact path="/mapas" render={() => <Maps />} />
        <Route exact path="/admin" render={() => <Admin />} />
        <Route exact path="/admin/:username" render={() => <AdminUser />} />
        <Route
          exact
          path="/mapas/:username/:id/editar"
          render={() => (
            <RedirectToViewer>
              <Editor />
            </RedirectToViewer>
          )}
        />
        <Route
          exact
          path="/mapas/:username/:id/"
          render={() => <MapViewer />}
        />
        <Route exact path="/info/nota-legal" render={() => <Legal />} />
        <Route exact path="/info/acerca-de" render={() => <About />} />
        <Route path="*" render={() => <NotFound />} />
      </Switch>
    </main>
  );
};
