import { FullPageSection, FullPageText } from "@/components/FullPageComponents";
import { Title } from "@/components/Title";
import React from "react";

export const SmallScreen = () => {
  return (
    <FullPageSection>
      <div className="p-4">
        <Title>O tamaño da pantalla é incorrecto</Title>
        <FullPageText>
          Sentímolo, pero esta aplicación só funciona en pantallas de tablet ou
          maiores.
        </FullPageText>
      </div>
    </FullPageSection>
  );
};
