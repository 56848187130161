import React from "react";

export const Title = ({ children }) => {
  return <h1 className="text-3xl font-black mb-4">{children}</h1>;
};

export const SubTitle = ({ children }) => {
  return <h2 className="text-2xl font-bold mb-2">{children}</h2>;
};

export const ExtraTitle = ({ children }) => {
  return <h3 className="font-bold text-lg mb-1">{children}</h3>;
};
