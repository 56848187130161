import { IconASF } from "@/components/Icons";
import React from "react";
import { Link } from "react-router-dom";

const FooterLink = ({ children }) => {
  return <li className="ml-4">{children}</li>;
};

export const Footer = () => {
  return (
    <footer className="py-2 px-4 flex bg-blue-700 fixed bottom-8 left-4 right-4 shadow-xl">
      <p className="flex-grow font-bold text-white">
        <a
          href="http://www.galicia.asfes.org/"
          target="_blank"
          rel="noreferrer"
          className="flex items-center"
        >
          <IconASF className="mr-2" /> Arquitectura sen Fronteiras
        </a>
      </p>

      <ul className="flex">
        <FooterLink>
          <Link to="/info/acerca-de" className="text-white">
            Sobre esta aplicación
          </Link>
        </FooterLink>
        <FooterLink>
          <Link to="/info/nota-legal" className="text-white">
            Nota Legal
          </Link>
        </FooterLink>
      </ul>
    </footer>
  );
};
