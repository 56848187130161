import React from "react";
import ReactDOM from "react-dom";

import App from "./src/App.js";
import { StatusProvider } from "./src/context/StatusContext.js";
import { UserProvider } from "./src/context/UserContext.js";

ReactDOM.render(
  <StatusProvider>
    <UserProvider>
      <App />
    </UserProvider>
  </StatusProvider>,
  document.getElementById("app")
);

/* eslint-disable */
if (module.hot) {
  module.hot.accept();
}
