import L from "leaflet/dist/leaflet";

export const mapIcon = ({ color, opacity = 0.7, moving = false }) =>
  new L.DivIcon({
    className: "map-icon",
    html: moving
      ? `<svg width="26" height="31" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M-.103 12.918C-.103 5.073 6.072 0 12.897 0c6.824 0 13 5.073 13 12.918 0 5.01-3.982 10.902-11.912 17.692-.617.52-1.543.52-2.161 0C3.878 23.82-.103 17.928-.103 12.918zM12.906 1.76l3.938 6.823H8.968l3.939-6.823zM2.138 12.523L8.96 8.584v7.878l-6.822-3.94zM12.906 23.3l-3.939-6.822h7.877L12.906 23.3zm3.927-6.838l6.822-3.94-6.822-3.938v7.878z" fill="${color}" opacity="${opacity}"/></svg>`
      : `<svg width="26" height="31" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.896 0c-6.824 0-13 5.073-13 12.918 0 5.01 3.982 10.902 11.928 17.692.617.52 1.544.52 2.161 0 7.93-6.79 11.912-12.682 11.912-17.692 0-7.845-6.175-12.918-13-12.918zm0 15.754c-1.787 0-3.25-1.418-3.25-3.15 0-1.734 1.463-3.152 3.25-3.152 1.788 0 3.25 1.418 3.25 3.151 0 1.733-1.462 3.151-3.25 3.151z" fill="${color}" opacity="${opacity}"/></svg>`,
    iconSize: [25, 30],
    iconAnchor: [12, 30],
  });
